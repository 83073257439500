const firebaseConfig = {
    apiKey: "AIzaSyC_URT5Ru9YQ05zupF4ekXzy6UDF10Eu5s",
    authDomain: "curso-canva.firebaseapp.com",
    databaseURL: "https://curso-canva-default-rtdb.firebaseio.com",
    projectId: "curso-canva",
    storageBucket: "curso-canva.appspot.com",
    messagingSenderId: "431627158901",
    appId: "1:431627158901:web:8892efe0e9c708788c7f3c",
    measurementId: "G-54R3KP19PJ"
  };

export default firebaseConfig